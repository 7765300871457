import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_RANGE_OPTIONS } from 'src/constant/filters';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
// ----------------------------------------------------------------------

dayjs.extend(duration);
dayjs.extend(relativeTime);

/**
 * Docs: https://day.js.org/docs/en/display/format
 */
export const formatStr = {
  dateTime: 'lll', // Use locale-aware format
  date: 'll', // Use locale-aware format
  time: 'LT', // Use locale-aware format
  split: {
    dateTime: 'L LT', // Use locale-aware format
    date: 'L', // Use locale-aware format
  },
  paramCase: {
    dateTime: 'DD-MM-YYYY HH:mm', // Keep as-is for consistent param format
    date: 'DD-MM-YYYY', // Keep as-is for consistent param format
  },
};

export function today(format) {
  return dayjs(new Date()).startOf('day').format(format);
}

// ----------------------------------------------------------------------

/** output: 17 Apr 2022 12:00 am
 */
export function fDateTime(date, format) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  return isValid ? dayjs(date).format(format ?? formatStr.dateTime) : 'Invalid time value';
}

// ----------------------------------------------------------------------

/** output: 17 Apr 2022
 */
export function fDate(date, format) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  return isValid
    ? dayjs(getDateFromISOString(date)).format(format ?? formatStr.date)
    : 'Invalid time value';
}

// ----------------------------------------------------------------------

/** output: 12:00 am
 */
export function fTime(date, format) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  return isValid ? dayjs(date).format(format ?? formatStr.time) : 'Invalid time value';
}

// ----------------------------------------------------------------------

/** output: 1713250100
 */
export function fTimestamp(date) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  return isValid ? dayjs(getDateFromISOString(date)).valueOf() : 'Invalid time value';
}

// ----------------------------------------------------------------------

/** output: a few seconds, 2 years
 */
export function fToNow(date) {
  if (!date) {
    return null;
  }

  const isValid = dayjs(date).isValid();

  return isValid ? dayjs(date).toNow(true) : 'Invalid time value';
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsBetween(inputDate, startDate, endDate) {
  if (!inputDate || !startDate || !endDate) {
    return false;
  }

  const formattedInputDate = fTimestamp(inputDate);
  const formattedStartDate = fTimestamp(startDate);
  const formattedEndDate = fTimestamp(endDate);

  if (formattedInputDate && formattedStartDate && formattedEndDate) {
    return formattedInputDate >= formattedStartDate && formattedInputDate <= formattedEndDate;
  }

  return false;
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsAfter(startDate, endDate) {
  return dayjs(startDate).isAfter(endDate);
}

// ----------------------------------------------------------------------

/** output: boolean
 */
export function fIsSame(startDate, endDate, units) {
  if (!startDate || !endDate) {
    return false;
  }

  const isValid = dayjs(startDate).isValid() && dayjs(endDate).isValid();

  if (!isValid) {
    return 'Invalid time value';
  }

  return dayjs(startDate).isSame(endDate, units ?? 'year');
}

// ----------------------------------------------------------------------

/** output:
 * Same day: 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same month: 25 - 26 Apr 2024
 * Same year: 25 Apr - 26 May 2024
 */
export function fDateRangeShortLabel(startDate, endDate, initial) {
  const isValid = dayjs(startDate).isValid() && dayjs(endDate).isValid();

  const isAfter = fIsAfter(startDate, endDate);

  if (!isValid || isAfter) {
    return 'Invalid time value';
  }

  let label = `${fDate(startDate)} - ${fDate(endDate)}`;

  if (initial) {
    return label;
  }

  const isSameYear = fIsSame(startDate, endDate, 'year');
  const isSameMonth = fIsSame(startDate, endDate, 'month');
  const isSameDay = fIsSame(startDate, endDate, 'day');

  if (isSameYear && !isSameMonth) {
    label = `${fDate(startDate, 'DD MMM')} - ${fDate(endDate)}`;
  } else if (isSameYear && isSameMonth && !isSameDay) {
    label = `${fDate(startDate, 'DD')} - ${fDate(endDate)}`;
  } else if (isSameYear && isSameMonth && isSameDay) {
    label = `${fDate(endDate)}`;
  }

  return label;
}

/** output: '2024-05-28T05:55:31+00:00'
 */
export function fAdd({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}) {
  const result = dayjs()
    .add(
      dayjs.duration({
        years,
        months,
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
      })
    )
    .format();

  return result;
}

/** output: '2024-05-28T05:55:31+00:00'
 */
export function fSub({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}) {
  const result = dayjs()
    .subtract(
      dayjs.duration({
        years,
        months,
        days,
        hours,
        minutes,
        seconds,
        milliseconds,
      })
    )
    .format();

  return result;
}

export function getDateRangeLabel(startDateArg, endDateArg, returnLabel = false) {
  const dateOptions = DATE_RANGE_OPTIONS;
  const startDate = new Date(new Date(startDateArg).toISOString().split('T')[0] + 'T00:00:00.000Z');
  const endDate = new Date(new Date(endDateArg).toISOString().split('T')[0] + 'T23:59:59.999Z');
  if (!startDate && !endDate) return dateOptions[0].label;
  if (!(dayjs(startDate).isValid() && dayjs(endDate).isValid())) {
    return dateOptions[0].label;
  }
  const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00.000Z');
  const todayEnd = new Date(new Date().toISOString().split('T')[0] + 'T23:59:59.999Z');
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  if (
    new Date(startDate).toDateString() === new Date(today).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[0].code;
  if (
    new Date(startDate).toDateString() === new Date(oneWeekAgo).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[1].code;
  if (
    new Date(startDate).toDateString() === new Date(twoWeeksAgo).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[2].code;
  if (
    new Date(startDate).toDateString() === new Date(thirtyDaysAgo).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[3].code;
  if (
    new Date(startDate).toDateString() === new Date(threeMonthsAgo).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[4].code;
  if (
    new Date(startDate).toDateString() === new Date(sixMonthsAgo).toDateString() &&
    new Date(endDate).toDateString() === new Date(todayEnd).toDateString()
  )
    return dateOptions[5].code;
  if (returnLabel) {
    return dateOptions[6].code;
  }
  return fDateRangeShortLabel(startDate, endDate);
}
export function isDateOlderThan86Days(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  // Ensure dates are valid before comparing
  if (start.toString() !== 'Invalid Date' && end.toString() !== 'Invalid Date') {
    if (start.getTime() <= sixMonthsAgo.getTime() || end.getTime() <= sixMonthsAgo.getTime()) {
      return true;
    }
  }
  return false;
}

export function getDateFromISOString(isoString) {
  return dayjs.utc(isoString).local().toDate();
}

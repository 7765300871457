import { create } from 'zustand';
import axiosInstance from 'src/utils/axios';
import { NOVA_BANK_SPACIFICATION, READ_UNREAD, TABS, TABS_FILTER_TYPE } from 'src/constant/filters';
import { fDate, fIsBetween, formatStr, getDateFromISOString } from 'src/utils/format-time';
import { ROOTS } from 'src/routes/paths';

const defaultFilters = {
  name: '',
  alertType: [],
  docType: [],
  sourceType: [],
  topicsType: [],
  applicableAlerts: false,
  tabFilter: [],
  startDate: null,
  endDate: null,
  page: 0,
  assignedUsers: [],
  readUnread: [],
};

const useAlertStore = create((set, get) => ({
  alerts: [],
  alertSummary: null,
  alertTitle: '',
  userList: [],
  isLoading: false,
  error: null,
  summaryError: null,
  isLoadingSummary: false,
  timeStamp: null,
  novaBankSpacification: NOVA_BANK_SPACIFICATION,
  filters: (() => {
    try {
      const storedFilters = JSON.parse(localStorage.getItem('filters'));
      let filters = storedFilters ? { ...defaultFilters, ...storedFilters } : defaultFilters;

      // Check if assignedUsers is empty and user_id exists in localStorage
      if (
        (!filters.assignedUsers || filters.assignedUsers.length === 0) &&
        localStorage.getItem('user_id')
      ) {
        filters.assignedUsers = [localStorage.getItem('user_id')];
      }

      // Check if dates are null and set default date range
      // if (!filters.startDate || !filters.endDate) {
      const startDate = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00.000Z');
      startDate.setMonth(startDate.getMonth() - 6);
      filters.startDate = startDate.toISOString();

      const endDate = new Date(new Date().toISOString().split('T')[0] + 'T23:59:59.999Z');
      filters.endDate = endDate.toISOString();
      // }

      return filters;
    } catch {
      return defaultFilters;
    }
  })(),
  dataFiltered: [],
  dataInPage: [],
  notFound: false,
  canReset: false,
  canResetAll: false,
  headingText: '',
  dynamicFilterLabel: [],
  horizons: [],
  fetchHorizons: async () => {
    // Check session storage first
    const cachedHorizons = sessionStorage.getItem('horizons');
    if (cachedHorizons) {
      set({ horizons: JSON.parse(cachedHorizons) });
      return;
    }

    try {
      const response = await axiosInstance.get('/horizons');
      // Extract just the names into an array
      const horizonNames = [...new Set(response.data.map((horizon) => horizon))];
      // Store in state
      set({ horizons: horizonNames });
      // Cache in session storage
      sessionStorage.setItem('horizons', JSON.stringify(horizonNames));
    } catch (error) {}
  },

  fetchAlerts: async (lang = 'en') => {
    set({ isLoading: true });
    try {
      // const { startDate: start_date, endDate: end_date } = get().filters;
      // let params = {
      //   start_date: null,
      //   end_date: null,
      // };
      // let params = {
      //   lang: lang,
      // };
      // if (!start_date || !end_date) {
      //   const date = new Date();
      //   date.setMonth(date.getMonth() - 6);
      //   date.setHours(0, 0, 0, 0);
      //   params.start_date = date.toISOString();
      //   params.end_date = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();
      // } else {
      //   params.start_date = new Date(new Date(start_date).setHours(0, 0, 0, 0)).toISOString();
      //   params.end_date = new Date(new Date(end_date).setHours(23, 59, 59, 999)).toISOString();
      // }

      //  if(isRefresh){
      //     params.start_date = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString();
      //     params.end_date = new Date().toISOString();
      //  }
      //  const params = {
      //   start_date: new Date(start_date).toISOString(),
      //   end_date: new Date(end_date).toISOString()
      // };
      const response = await axiosInstance.get(
        `${ROOTS.ALERTS}`
        // lang === 'pt' ? { params } : undefined
      );
      const updatedResponse =
        lang === 'pt'
          ? response.data.map((item) => ({
              ...item,
              title: item?.extra?.translations?.[lang]?.title || item.title,
            }))
          : response.data;
      set({
        alerts: updatedResponse,
        isLoading: false,
        error: null,
        timeStamp: fDate(new Date(), formatStr.dateTime),
      });
      get().applyFilter();
    } catch (error) {
      set({ error: error.message, isLoading: false, timeStamp: new Date() });
    }
  },
  fetchAlertById: async (id, lang = 'en', isReload = true) => {
    let params = {
      lang: lang,
    };
    set({ isLoadingSummary: isReload, alertTitle: isReload ? '' : get().alertTitle });
    try {
      const response = await axiosInstance.get(
        `${ROOTS.ALERTS}/${id}`,
        lang === 'pt' ? { params } : undefined
      );
      set({
        alertSummary: response.data,
        isLoadingSummary: false,
        summaryError: null,
        alertTitle: response.data.title || '',
      });
    } catch (error) {
      set({ summaryError: error.message, isLoadingSummary: false });
    }
  },
  fetchNovaBankSpacification: async () => {
    const response = await axiosInstance.get('/nova-bank-spacification');
    set({ novaBankSpacification: response.data || [] });
  },
  setError: (error) => {
    set({ error });
  },
  setSummaryError: (error) => {
    set({ summaryError: error });
  },
  fetchUserList: async () => {
    try {
      const response = await axiosInstance.get('/users');
      set({ userList: response.data });
    } catch (error) {}
  },

  updateFilters: (newFilters, isFetchAlerts = false) => {
    set((state) => {
      const updatedFilters = { ...state.filters, ...newFilters, page: 0 };
      if (!isFetchAlerts) {
        localStorage.setItem('filters', JSON.stringify(updatedFilters));
      }
      return { filters: updatedFilters };
    });
    // if (isFetchAlerts) {
    //   get().fetchAlerts();
    //   return;
    // }

    get().applyFilter();
  },

  resetFilters: () => {
    const {
      startDate: currentStartDate,
      endDate: currentEndDate,
      applicableAlerts,
      assignedUsers,
      tabFilter,
    } = get().filters;
    const resetFilters = {
      ...defaultFilters,
      tabFilter,
      startDate: currentStartDate,
      endDate: currentEndDate,
      applicableAlerts,
      assignedUsers,
    };
    localStorage.setItem('filters', JSON.stringify(resetFilters));
    set({ filters: resetFilters });
    get().applyFilter();
  },

  updateAlert: (updatedAlert) => {
    set((state) => ({
      alerts: state.alerts.map((alert) => (alert.id === updatedAlert.id ? updatedAlert : alert)),
    }));
    get().applyFilter();
  },
  updateAlertSummary: (updatedAlert) => {
    set({ alertSummary: updatedAlert });
  },
  deleteItem: (id) => {
    set((state) => ({
      alerts: state.alerts.filter((alert) => alert.id !== id),
    }));
    get().applyFilter();
  },

  deleteItems: (selectedIds) => {
    set((state) => ({
      alerts: state.alerts.filter((alert) => !selectedIds.includes(alert.id)),
    }));
    get().applyFilter();
  },

  setHeadingText: (value) => {
    const headingText = TABS.find((tab) => tab.value === value)?.headingText;
    set({ headingText });
  },
  updateNovaBankSpacification: async (id, value, isDates = false) => {
    try {
      let response;
      if (isDates) {
        response = await axiosInstance.patch(`/alerts/${id}/nb`, value);
      } else {
        response = await axiosInstance.put(`/alerts/${id}/nb/matrix/${value.id}`);
      }

      if (response.status === 200) {
        get().updateAlertSummary({
          ...get().alertSummary,
          extra: {
            ...get().alertSummary.extra,
            nb: response.data,
          },
        });
        return true;
      }
    } catch (error) {
      throw error;
    }
  },
  updateAlertStar: async (id, isStarred, isSummary = false) => {
    const endpoint = `alerts/${id}/star`;
    if (!isSummary) {
      get().updateAlert({ ...get().alerts.find((alert) => alert.id === id), star: isStarred });
    }
    try {
      const response = await axiosInstance({
        method: isStarred ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        if (isSummary) {
          get().updateAlertSummary({ ...get().alertSummary, star: isStarred });
        }
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      if (!isSummary) {
        get().updateAlert({ ...get().alerts.find((alert) => alert.id === id), star: !isStarred });
      }
      throw error;
    }
  },

  updateAlertApplicable: async (id, isApplicable, isSummary = false) => {
    const endpoint = `alerts/${id}/applicable`;
    if (!isSummary) {
      get().updateAlert({
        ...get().alerts.find((alert) => alert.id === id),
        profile_match: isApplicable,
      });
    }
    try {
      const response = await axiosInstance({
        method: isApplicable ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        if (isSummary) {
          get().updateAlertSummary({
            ...get().alertSummary,
            profile_match: isApplicable,
          });
        } else {
          get().updateAlert({
            ...get().alerts.find((alert) => alert.id === id),
            profile_match: isApplicable,
          });
        }
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      if (!isSummary) {
        get().updateAlert({
          ...get().alerts.find((alert) => alert.id === id),
          profile_match: !isApplicable,
        });
      }
      throw error;
    }
  },

  updateAlertRead: async (id, isMakeRead = true, isSummary = false) => {
    const endpoint = `alerts/${id}/read`;
    if (!isSummary) {
      get().updateAlert({ ...get().alerts.find((alert) => alert.id === id), read: isMakeRead });
    }
    try {
      const response = await axiosInstance({
        method: isMakeRead ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        if (isSummary) {
          get().updateAlertSummary({ ...get().alertSummary, read: isMakeRead });
        }
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      if (!isSummary) {
        get().updateAlert({ ...get().alerts.find((alert) => alert.id === id), read: !isMakeRead });
      }
      throw new Error('Failed to update read status');
    }
  },
  updateAlertStatus: async (id, newStatus, isSummary = false) => {
    if (!isSummary) {
      get().updateAlert({
        ...get().alerts.find((alert) => alert.id === id),
        status: newStatus,
      });
    }
    try {
      const response = await axiosInstance.put(`${ROOTS.ALERTS}/${id}/status/${newStatus}`);

      if (response.status === 200) {
        // Update local state through onRowUpdate
        const updatedAlert = {
          ...get().alertSummary,
          status: newStatus,
        };
        if (isSummary) {
          get().updateAlertSummary(updatedAlert);
        }
        return true;
      }
      throw new Error('Failed to update status');
    } catch (error) {
      if (!isSummary) {
        get().updateAlert({
          ...get().alerts.find((alert) => alert.id === id),
          status: newStatus === 'pending' ? 'done' : 'pending',
        });
      }
      throw error;
    }
  },
  updateAlertActionable: async (id, isActionable, isSummary = false) => {
    const endpoint = `alerts/${id}/actionable`;
    try {
      const response = await axiosInstance({
        method: isActionable ? 'put' : 'delete',
        url: endpoint,
      });
      if (response.status === 200) {
        if (isSummary) {
          get().updateAlertSummary({
            ...get().alertSummary,
            extra: {
              ...get().alertSummary.extra,
              alert_type: isActionable ? 'actionable' : 'informative',
            },
          });
        } else {
          get().updateAlert({
            ...get().alerts.find((alert) => alert.id === id),
            extra: {
              ...get().alerts.find((alert) => alert.id === id).extra,
              alert_type: isActionable ? 'actionable' : 'informative',
            },
          });
        }

        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      throw error;
    }
  },

  assignAlertToUser: async (alertId, userId, isSummary = false) => {
    try {
      const response = await axiosInstance.put(`alerts/${alertId}/user_id/${userId}`);
      if (response.status === 200) {
        if (isSummary) {
          get().updateAlertSummary({
            ...get().alertSummary,
            user_id: userId,
          });
        } else {
          get().updateAlert({
            ...get().alerts.find((alert) => alert.id === alertId),
            user_id: userId,
          });
        }
        return true;
      }
      throw new Error('Unexpected response status');
    } catch (error) {
      throw error;
    }
  },

  applyFilter: () => {
    const { alerts, filters } = get();
    const {
      name,
      alertType,
      docType,
      sourceType,
      topicsType,
      applicableAlerts,
      tabFilter,
      startDate,
      endDate,
      assignedUsers,
      readUnread,
    } = filters;

    let filteredData = [...alerts];

    if (name) {
      filteredData = filteredData.filter(
        (item) =>
          item.title?.toLowerCase().includes(name.toLowerCase()) ||
          item.description?.toLowerCase().includes(name.toLowerCase())
      );
    }

    if (alertType?.length) {
      filteredData = filteredData.filter((item) => {
        if (item.extra && item.extra.alert_type) {
          return alertType.includes(item.extra.alert_type);
        }
        return alertType.includes('informative');
      });
    }

    if (docType?.length) {
      filteredData = filteredData.filter((item) => {
        if (item.extra && item.extra.document_type) {
          return docType.includes(item.extra.document_type);
        }
        return docType.includes('Others');
      });
    }

    if (sourceType?.length) {
      filteredData = filteredData.filter((item) =>
        item.horizon_name ? sourceType.includes(item.horizon_name) : sourceType.includes('Others')
      );
    }

    if (topicsType?.length) {
      filteredData = filteredData.filter((item) => {
        if (item.extra && Array.isArray(item.extra.topics)) {
          return item.extra.topics.some((topic) => topicsType.includes(topic));
        }
        return topicsType.includes('Others');
      });
    }

    if (applicableAlerts) {
      filteredData = filteredData.filter((item) => item.profile_match);
    }

    if (readUnread.length) {
      filteredData = filteredData.filter((item) =>
        readUnread.includes(item.read ? READ_UNREAD[0] : READ_UNREAD[1])
      );
    }

    filteredData = filteredData.filter((item) => {
      if (tabFilter.includes(TABS_FILTER_TYPE[0])) {
        return (!item.status || (item.status && item.status === 'pending')) && !item.user_id;
      }
      if (tabFilter.includes(TABS_FILTER_TYPE[1])) {
        return (!item.status || (item.status && item.status === 'pending')) && item.user_id;
      }
      if (tabFilter.includes(TABS_FILTER_TYPE[3])) {
        return item.star;
      }
      return true;
    });

    if (!tabFilter.includes(TABS_FILTER_TYPE[3]) && startDate && endDate) {
      filteredData = filteredData.filter((item) =>
        fIsBetween(getDateFromISOString(item.updated_at), startDate, endDate)
      );
    }

    if (
      !tabFilter.includes(TABS_FILTER_TYPE[0]) &&
      !tabFilter.includes(TABS_FILTER_TYPE[3]) &&
      assignedUsers.length
    ) {
      filteredData = filteredData.filter((item) => {
        if (assignedUsers.some((id) => id === '')) {
          return (
            !item.user_id || item.user_id === '' || assignedUsers.some((id) => id === item.user_id)
          );
        }
        return assignedUsers.some((id) => id === item.user_id);
      });
    }

    // const comparator = getComparator(order, orderBy);
    // filteredData.sort((a, b) => {
    //   const order = comparator(a, b);
    //   if (order !== 0) return order;
    //   return filteredData.indexOf(a) - filteredData.indexOf(b);
    // });

    const canReset =
      !!name ||
      alertType.length > 0 ||
      docType.length > 0 ||
      sourceType.length > 0 ||
      topicsType.length > 0 ||
      readUnread.length > 0;

    set({
      dataFiltered: filteredData,
      notFound: (!filteredData.length && canReset) || !filteredData.length,
      canReset,
      canResetAll: canReset || applicableAlerts || assignedUsers.length > 0,
    });
    get().updateDataInPage();
  },

  updateDataInPage: () => {
    const { dataFiltered, filters } = get();
    const { page, rowsPerPage } = filters;
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    set({ dataInPage: dataFiltered.slice(start, end) });
  },
}));

export { useAlertStore };
export default useAlertStore;
